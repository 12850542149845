import {
  ChangeEventHandler,
  FunctionComponent,
  useEffect,
  useState,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ChangeEvent,
  ReactNode,
} from 'react';
import classNames from 'classnames';
import { Loader } from '../../../public/frontend/components/Loader';
type Loading = number | boolean;

export type FakeInputProps = {
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFilter?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  label?: ReactNode;
  loading?: boolean | { delay?: number };
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'onBlur' | 'onFocus'
>;

export const FakeInput: FunctionComponent<FakeInputProps> = props => {
  const { loading = false, disabled, className, label, ...rest } = props;
  const [value, setValue] = useState(props.value);

  const [innerLoading, setLoading] = useState<Loading>(!!loading);

  const loadingOrDelay: Loading =
    typeof loading === 'boolean' ? loading : loading?.delay || true;

  useEffect(() => {
    let delayTimer: number | null = null;

    if (typeof loadingOrDelay === 'number') {
      delayTimer = window.setTimeout(() => {
        delayTimer = null;
        setLoading(loadingOrDelay);
      }, loadingOrDelay);
    } else {
      setLoading(loadingOrDelay);
    }

    return () => {
      if (delayTimer) {
        // in order to not perform a React state update on an unmounted component
        // and clear timer after 'loadingOrDelay' updated.
        window.clearTimeout(delayTimer);
        delayTimer = null;
      }
    };
  }, [loadingOrDelay]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props;

    if (innerLoading || disabled) {
      e.preventDefault();
      return;
    }
    setValue(e.currentTarget.value);
    (onChange as ChangeEventHandler<HTMLInputElement>)?.(e);
  };

  const handleBlur = (e: ChangeEvent<HTMLInputElement>) => {
    const { onBlur } = props;

    if (innerLoading || disabled) {
      e.preventDefault();
      return;
    }
    setValue(e.currentTarget.value);
    (onBlur as ChangeEventHandler<HTMLInputElement>)?.(e);
  };

  const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props;

    if (innerLoading || disabled) {
      e.preventDefault();
      return;
    }
    setValue(e.currentTarget.value);
    (onChange as ChangeEventHandler<HTMLInputElement>)?.(e);
  };

  return (
    <div className={classNames(['FakeInput', className])}>
      <input
        {...rest}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        value={value}
        disabled={(typeof loading === 'boolean' && loading) || disabled}
      />
      {label}
      <div className="fake-input js-input-animate">{value}</div>
    </div>
  );
};
