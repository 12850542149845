import { useEffect, FunctionComponent, useRef } from 'react';
import { useLocation, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { useMount } from 'react-use';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useProfile } from '~components/profile/hooks';
import { useConfig, useGetGaTestId } from '~components/hooks';
import { UpButton } from '~components/UpButton';
import Notifications from '~components/notifications';

import ErrorBoundary from '../error/ErrorBoundary';
import Header from './Header';
import Footer from './Footer';

import {
  LackyModal,
  BonusModal,
  ForgotModal,
  WithdrawNone,
  OutItemModal,
  TradeUrlSave,
  AuthSpecial,
  PromoModal1,
  PromoModal2,
  PromoModal3,
  PromoModal4,
  PromoModal7,
  PromoModal8,
  PromoModal9,
  PromoModal10,
  PromoModal11,
  PromoModal12,
  PromoModal13,
  WheelPrize,
  WinDemoModal,
  DistributionModal,
  ExitPopup,
  DistributionWinModal,
  DistributionUserWinModal,
  PhoneNumberModal,
} from '~components/modals';
import { useOpenModal } from '~components/modals/hooks';
import { useYaMetrica } from '~frontend/provider/yametrica';

const notyModify = () => {
  const container = document.querySelector('.Toastify__toast-container');

  if (container) {
    if (
      window.pageYOffset <= 240 &&
      !document.querySelector('.backdrop.visible')
    ) {
      container.classList.add('absolute');
    } else {
      container.classList.remove('absolute');
    }
  }
};

const HrefLang: FunctionComponent = () => {
  const {
    i18n: { languages },
  } = useTranslation();
  const getConfig = useConfig();
  const { pathname } = useLocation();

  if (getConfig?.seo.language === 'ru') {
    return null;
  }

  return (
    <Helmet>
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`https://${getConfig?.hostname}/${getConfig?.seo.language}${pathname}`}
      />
      {languages.map(locale => (
        <link
          rel="alternate"
          href={`https://${getConfig?.hostname}/${locale}${pathname}`}
          hrefLang={locale}
          key={locale}
        />
      ))}
    </Helmet>
  );
};

const MainLayout: FunctionComponent = () => {
  const [ga_ab_test, ga_abc] = useGetGaTestId();
  const yametrica = useYaMetrica();
  const { isActiveModal, onCloseAll, onShow } = useOpenModal();
  const {
    i18n: { language },
  } = useTranslation();
  const getConfig = useConfig();
  const getProfile = useProfile();
  const scrollRef = useRef(null);
  const location = useLocation();
  const [cookies] = useCookies(['modal', 'disableSound']);

  const loadAudio = () => {
    if (document.querySelector('audio') && !(cookies.disableSound === 'true')) {
      document.querySelector('audio').volume = 0.2;
      document.querySelector('audio').play();
    }
    window.removeEventListener('click', loadAudio);
  };

  useMount(() => {
    if (cookies?.modal) {
      try {
        const { type, props } =
          typeof cookies?.modal === 'object'
            ? (cookies?.modal as any)
            : JSON.parse(cookies?.modal);

        onShow(type, props);
      } catch (error) {
        console.log(error);
      }
    }

    // yametrica.sendApi(1, 'event', 'experiment_impression', {
    //   experiment_id: 'categoryup',
    //   variant_id: `categoryup.${ga_ab}`,
    //   send_to: 'G-C5XHV4XCZ9',
    // });

    // yametrica.sendApi(1, 'event', 'experiment_impression', {
    //   experiment_id: 'show_drop_chance',
    //   variant_id: `show_drop_chance.${ga_ab_test}`,
    //   send_to: 'G-C5XHV4XCZ9',
    // });

    // yametrica.sendApi(1, 'event', 'experiment_impression', {
    //   experiment_id: 'XFMPhFG2Q8-C7S3PsEeT7A',
    //   variant_id: `XFMPhFG2Q8-C7S3PsEeT7A.${ga_ab}`,
    //   send_to: 'G-C5XHV4XCZ9',
    // });

    // yametrica.sendApi(1, 'event', 'experiment_impression', {
    //   experiment_id: 'IrBZZGAbRNmmAtH43EZCVw',
    //   variant_id: `IrBZZGAbRNmmAtH43EZCVw.${ga_abc}`,
    //   send_to: 'G-C5XHV4XCZ9',
    // });

    window.addEventListener('click', loadAudio);
  });

  const handleScrol = () => {
    notyModify();
  };

  useEffect(() => {
    const unsubscribe = toast.onChange(() => {
      notyModify();
    });

    window.addEventListener('scroll', handleScrol);

    return () => {
      unsubscribe();
      window.removeEventListener('scroll', handleScrol);
    };
  });

  useEffect(() => {
    return () => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      onCloseAll();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (isActiveModal && document.body.querySelector('.box-modal')) {
      document.body.classList.add('overflow');
    } else {
      document.body.classList.remove('overflow');
    }
  }, [isActiveModal]);

  const title =
    _.get(getConfig, `seo[${language}].title`) || getConfig?.seo?.title;
  const description =
    _.get(getConfig, `seo[${language}].description`) ||
    getConfig?.seo?.description;
  const keywords =
    _.result(getConfig, `seo[${language}].keywords`) ||
    getConfig?.seo?.keywords;

  return (
    <ErrorBoundary>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <link
          href={`https://${getConfig?.hostname}${location.pathname}`}
          rel="canonical"
        />
      </Helmet>

      <HrefLang />
      <DistributionModal />
      <WinDemoModal />
      {getProfile ? (
        <>
          <Notifications />
          <LackyModal />
          <BonusModal />
          <WithdrawNone />
          <OutItemModal />
          <PhoneNumberModal />
          <TradeUrlSave />
          <PromoModal1 />
          <PromoModal2 />
          <PromoModal3 />
          <PromoModal4 />
          <PromoModal7 />
          <PromoModal8 />
          <PromoModal9 />
          <PromoModal10 />
          <PromoModal11 />
          <PromoModal12 />
          <PromoModal13 />
          <WheelPrize />
          <DistributionWinModal />
          <DistributionUserWinModal />
          {getProfile?.getWheelStep > 0 && <ExitPopup />}
        </>
      ) : (
        <>
          <ForgotModal />
          <AuthSpecial />
        </>
      )}
      {getConfig?.getEvent?.props?.audio && (
        <audio
          hidden
          loop
          muted={String(cookies.disableSound) === 'true'}
          src={getConfig?.getEvent?.props?.audio}
        />
      )}

      {getConfig?.getBattlePass?.props?.audio && (
        <audio
          hidden
          loop
          muted={String(cookies.disableSound) === 'true'}
          src={getConfig?.getBattlePass?.props?.audio}
        />
      )}

      <div
        data-pathname={location.pathname}
        data-host={getConfig?.hostname}
        data-language={language}
        data-region={getConfig?.isEU ? 'eu' : 'cis'}
        data-acitve-modal={String(isActiveModal)}
        className={`main ${!getProfile ? 'not-author' : 'author'} ${
          (!getConfig?.getSaleTime || !getConfig?.discount) && 'sales-timer-off'
        } ${getConfig?.seo?.distributionActive && 'distributions-active'}`}
        ref={scrollRef}
      >
        <ToastContainer
          enableMultiContainer={true}
          limit={3}
          draggable={false}
        />

        <ErrorBoundary>
          <Header />
        </ErrorBoundary>

        <section className="wrap">
          <div id="childrenBlock">
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </div>
          <ErrorBoundary>
            <Footer />
          </ErrorBoundary>
        </section>
        <div className="clear" />
        <UpButton />
      </div>
    </ErrorBoundary>
  );
};

export default MainLayout;
