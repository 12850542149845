import '../../scss/pages/_giveaway.scss';

import dayjs from 'dayjs';
import { useState, useRef, FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { gql, useQuery } from '@apollo/client';
import IconSkinPercent from '../../scss/images/icon-skin-percent.svg';
import { Loader } from '~frontend/components/Loader';
import { useTranslation } from 'react-i18next';

import noWinnerAvatar from '../../scss/images/giveaway-icon-not-ava.svg';

const GET_GIVEAWAY_WINNER = gql`
  query getGiveawayWinner(
    $promocode: String!
    $startDate: String!
    $endDate: String!
  ) {
    getGiveawayWinner(
      promocode: $promocode
      startDate: $startDate
      endDate: $endDate
    ) {
      user {
        id
        avatar
        userName
      }
      tickets
    }
  }
`;

const GET_GIVEAWAY_PARTICIPANTS = gql`
  query getGiveawayParticipants(
    $promocode: String!
    $startDate: String!
    $endDate: String!
  ) {
    getGiveawayParticipants(
      promocode: $promocode
      startDate: $startDate
      endDate: $endDate
    ) {
      user {
        id
        avatar
        userName
      }
      tickets
    }
  }
`;

const Giveaway: FunctionComponent = () => {
  const { t } = useTranslation();
  const [promocode, setPromocode] = useState('');
  const [startDate, setStartDate] = useState(
    dayjs().format('YYYY-MM-DD 00:00:00'),
  );
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD 23:59:59'));
  const [winner, setWinner] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 7;

  const startDateInputRef = useRef(null);
  const endDateInputRef = useRef(null);

  const {
    data: participantsData,
    loading: isLoadingParticipants,
    refetch: refetchPartisipants,
    error: participantsError,
  } = useQuery(GET_GIVEAWAY_PARTICIPANTS, {
    variables: { promocode, startDate, endDate },
    skip: true,
  });

  const { refetch: refetchWinner, error: winnerError } = useQuery(
    GET_GIVEAWAY_WINNER,
    {
      variables: { promocode, startDate, endDate },
      skip: true,
    },
  );

  const handleNextPage = () => {
    if (currentPage < Math.ceil(participants?.length / itemsPerPage) - 1) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handlePromocodeChange = (e) => {
    setPromocode(`${e.target.value}`.trim().toUpperCase());
  };

  const handleStartDateClick = () => {
    startDateInputRef.current.showPicker();
  };

  const handleEndDateClick = () => {
    endDateInputRef.current.showPicker();
  };

  const handleStartDateChange = (e) => {
    const formattedDate = dayjs(e.target.value).format('YYYY-MM-DD');
    setStartDate(`${formattedDate} 00:00:00`);
  };

  const handleEndDateChange = (e) => {
    const formattedDate = dayjs(e.target.value).format('YYYY-MM-DD');
    console.log(`${formattedDate} 23:59:59`);
    setEndDate(`${formattedDate} 23:59:59`);
  };

  const handleReload = async (e?) => {
    if (e) {
      e.target.classList.add('animate');
      e.target.addEventListener(
        'animationend',
        () => {
          e.target.classList.remove('animate');
        },
        { once: true },
      );
    }
    setWinner(null);
    const { data } = await refetchPartisipants();
    if (participantsError) toast.error(`error: ${participantsError.message}`);
    if (!data) toast.error('cannot fetch participants');
    const users = data.getGiveawayParticipants;
    if (users && users.length !== 0) {
      setParticipants(users);
    } else {
      setParticipants(null);
    }
  };

  const handleDraw = async () => {
    try {
      setIsLoading(true);
      await handleReload();
      const { data } = await refetchWinner();
      if (winnerError) toast.error(`error winner: ${winnerError}`);
      if (!data) toast.error('cannot refetchWinner');

      const user = data.getGiveawayWinner;
      if (user) {
        setIsLoading(false);
        setWinner(user);
      } else {
        setIsLoading(false);
        setWinner(undefined);
        toast.error(t('giveaway_no_users_found'), { autoClose: 3500 });
      }
    } catch (error) {
      setIsLoading(false);
      setWinner(undefined);
      toast.error(t(error.message), { autoClose: 3500 });
    }
  };

  if (isLoadingParticipants) {
    return <Loader />;
  } else if (participantsError) {
    return <>Giveaway | Error! {participantsError.message}</>;
  }

  return (
    <div className="giveaway-page">
      <div className="giveaway-content">
        <div className="giveaway-content-left">
          <div className="giveaway-winner">
            {isLoading ? (
              <Loader fon={false}></Loader>
            ) : (
              <>
                {winner ? (
                  <a className="user-ava" href={`/user/${winner.user.id}`}>
                    <img src={winner.user.avatar} alt="winner" />
                  </a>
                ) : (
                  <a className="user-ava" href="#">
                    <img src={noWinnerAvatar} alt="user avatar"></img>
                  </a>
                )}
                <div className="giveaway-winner-wrap">
                  <div className="giveaway-winner-title">
                    {winner && <b>🎉</b>}
                    <span>{t('Winner')}</span>
                    {winner && <b>🎉</b>}
                  </div>
                  <div className="user-name">
                    {winner ? (
                      <>
                        <span>{`#${
                          participants.findIndex(
                            (item) => item.user.id === winner.user.id,
                          ) + 1
                        } ${winner.user.userName}`}</span>
                        <div className="user-name-num">
                          <span>{winner.tickets}</span>
                          <div
                            className="icon material-icons"
                            data-icon="local_activity"
                          ></div>
                        </div>
                      </>
                    ) : (
                      <span>#0</span>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="giveaway-form">
            <div className="label">{t('Promo')}</div>
            <div className="input-gradient input-gradient-promo js-input-promo">
              <div className="icon material-icons">
                <img src={IconSkinPercent} alt="icon" />
              </div>
              <input
                value={promocode.toUpperCase()}
                onChange={handlePromocodeChange}
                className="js-pay-input-anim"
                type="text"
              />
            </div>
            <div className="giveaway-form-date">
              <div className="input-gradient input-gradient-promo js-input-promo">
                <div
                  className="icon material-icons"
                  data-icon="calendar_month"
                ></div>
                <input
                  ref={startDateInputRef}
                  className="js-pay-input-anim"
                  type="date"
                  placeholder="Start Date"
                  onClick={handleStartDateClick}
                  onChange={handleStartDateChange}
                  value={startDate ? dayjs(startDate).format('YYYY-MM-DD') : ''}
                />
              </div>
              <div className="input-gradient input-gradient-promo js-input-promo">
                <div
                  className="icon material-icons"
                  data-icon="calendar_month"
                ></div>
                <input
                  ref={endDateInputRef}
                  className="js-pay-input-anim"
                  type="date"
                  placeholder="End Date"
                  onChange={handleEndDateChange}
                  onClick={handleEndDateClick}
                  value={endDate ? dayjs(endDate).format('YYYY-MM-DD') : ''}
                />
              </div>
            </div>
          </div>
          <div className="giveaway-form-btns">
            <a
              className={`btn btn-violet ${
                isLoading || !promocode ? 'disabled' : ''
              }`}
              href="#"
              onClick={handleDraw}
            >
              {t('Giveaway')}
            </a>
            <a className="btn-replay" onClick={handleReload}>
              <div className="icon material-icons" data-icon="replay"></div>
            </a>
          </div>
        </div>
        <div className="giveaway-content-right">
          {participants ? (
            <div className="giveaway-table">
              <div className="giveaway-table-title">{t('Participants')}</div>
              <div className="giveaway-table-items">
                {participants.map((item, index) => {
                  return (
                    <div
                      className="giveaway-table-items-row"
                      key={item.user.id}
                    >
                      <div className="giveaway-table-items-row-num">
                        #{index + 1}
                      </div>

                      <div className="giveaway-table-items-row-user">
                        <a
                          className="giveaway-table-items-row-user-ava"
                          href={`/user/${item.user.id}`}
                        >
                          <img src={item.user.avatar} />
                          <div className="tooltipe">К профилю</div>
                        </a>
                        <div className="giveaway-table-items-row-user-name">
                          {item.user.userName}
                        </div>
                      </div>

                      <div className="giveaway-table-items-row-ticket">
                        <span>{item.tickets}</span>
                        <div className="icon material-icons">
                          local_activity
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="giveaway-table-items giveaway-table-items__mob">
                <div className="giveaway-table-items-row-parent">
                  {participants
                    .slice(
                      currentPage * itemsPerPage,
                      (currentPage + 1) * itemsPerPage,
                    )
                    .map((item, index) => {
                      return (
                        <div
                          className="giveaway-table-items-row"
                          key={item.user.id}
                        >
                          <div className="giveaway-table-items-row-num">
                            #{currentPage * itemsPerPage + index + 1}
                          </div>

                          <div className="giveaway-table-items-row-user">
                            <a
                              className="giveaway-table-items-row-user-ava"
                              href={`/user/${item.user.id}`}
                            >
                              <img src={item.user.avatar} />
                              <div className="tooltipe">К профилю</div>
                            </a>
                            <div className="giveaway-table-items-row-user-name">
                              {item.user.userName}
                            </div>
                          </div>

                          <div className="giveaway-table-items-row-ticket">
                            <span>{item.tickets}</span>
                            <div className="icon material-icons">
                              local_activity
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="giveaway-table-items-controls">
                <div className="giveaway-table-items-controls-line">
                  <div
                    className="giveaway-table-items-controls-line-progress"
                    // style={{ width: '33.3%' }}
                    style={{
                      width: `${
                        ((currentPage + 1) /
                          Math.ceil(participants?.length / itemsPerPage)) *
                        100
                      }%`,
                    }}
                  ></div>
                </div>
                <div className="giveaway-table-items-controls-btns">
                  <div
                    className="giveaway-table-items-controls-btns-left"
                    onClick={handlePreviousPage}
                  >
                    <div className="icom material-icons">chevron_left</div>
                  </div>
                  <div
                    className="giveaway-table-items-controls-btns-right"
                    onClick={handleNextPage}
                  >
                    <div className="icom material-icons">chevron_right</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="giveaway-table giveaway-table-empty">
              <div className="giveaway-table-title">{t('Participants')}</div>
              <div className="giveaway-table-items giveaway-table-items-empty">
                <span>{t('No participants')}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Giveaway;
