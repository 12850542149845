import { MouseEvent, MouseEventHandler, SyntheticEvent } from 'react';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
import _ from 'lodash';

import { AudioListener, Audio, AudioLoader } from 'three';

let isLoad = false;

if (typeof document !== 'undefined') {
  document.addEventListener('click', setLoad);
}

function setLoad() {
  isLoad = true;
  document.removeEventListener('click', setLoad);
}

const audioList: Array<string> = [];

const playAudio = (
  event: MouseEvent | SyntheticEvent | MouseEventHandler,
  audioName = '',
  audioValue = 0.2,
):
  | MouseEventHandler<HTMLAnchorElement>
  | MouseEventHandler<HTMLElement>
  | MouseEventHandler<HTMLDivElement>
  | MouseEventHandler<HTMLButtonElement>
  | boolean => {
  if (cookies.get('disableSound') === 'true') {
    return false;
  }

  const name = _.result(event, 'currentTarget.dataset.audio', audioName);
  const volume = _.result(event, 'currentTarget.dataset.volume', audioValue);
  const isAwait = _.result(event, 'currentTarget.dataset.isawait', false);

  if ((audioList.includes(name) && isAwait) || !isLoad) {
    return false;
  }

  audioList.push(name);

  const listener = new AudioListener();
  const sound = new Audio(listener);
  const audioLoader = new AudioLoader();

  audioLoader.load(`/audio/${name}`, (buffer) => {
    try {
      sound.setBuffer(buffer);
      sound.setVolume(volume);
      sound.play();
      sound.onEnded = () => {
        sound.isPlaying = false;
        _.remove(audioList, name);
      };
    } catch {
      //
    }
  });

  return true;
};

export default playAudio;
