import {
  FunctionComponent,
  useEffect,
  ReactNode,
  useState,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ChangeEvent,
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

type Loading = number | boolean;

export type InputProps = {
  onChange?: (value: number | string) => void;
  loading?: boolean | { delay?: number };
  children?: ReactNode;
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange'
>;

export const InputNumber: FunctionComponent<InputProps> = props => {
  const { loading = false, disabled, className, ...rest } = props;

  const [innerLoading, setLoading] = useState<Loading>(!!loading);

  const loadingOrDelay: Loading =
    typeof loading === 'boolean' ? loading : loading?.delay || true;

  useEffect(() => {
    let delayTimer: number | null = null;

    if (typeof loadingOrDelay === 'number') {
      delayTimer = window.setTimeout(() => {
        delayTimer = null;
        setLoading(loadingOrDelay);
      }, loadingOrDelay);
    } else {
      setLoading(loadingOrDelay);
    }

    return () => {
      if (delayTimer) {
        // in order to not perform a React state update on an unmounted component
        // and clear timer after 'loadingOrDelay' updated.
        window.clearTimeout(delayTimer);
        delayTimer = null;
      }
    };
  }, [loadingOrDelay]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props;

    if (innerLoading || disabled) {
      e.preventDefault();
      return;
    }

    if (e.target.value.length === 0) {
      onChange?.('');
      return;
    }

    if (!/^\d+$/.test(e.target.value)) {
      return;
    }

    let amount = Number(e.target.value);

    if (_.toInteger(rest?.min) > amount) {
      amount = _.toFinite(rest?.min);
    } else if (amount > _.toInteger(rest?.max)) {
      amount = _.toFinite(rest?.max);
    }

    onChange?.(amount);
  };

  return (
    <input
      {...rest}
      className={classNames(['Input', className])}
      onChange={handleInput}
      disabled={(typeof loading === 'boolean' && loading) || disabled}
    />
  );
};
