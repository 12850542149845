import { memo, FunctionComponent } from 'react';
import {
  dinero,
  multiply,
  toSnapshot,
  allocate,
  add,
  subtract,
} from 'dinero.js';
import { USD } from '@dinero.js/currencies';
import _ from 'lodash';

interface PriceProps {
  sum: number;
  ceil?: boolean;
}

export const convertNumber = (number: number, ceil = false) => {
  if (String(number).includes('.')) {
    return number;
  }

  const result = number / 100;

  if (ceil) {
    return _.ceil(result);
  }

  return result;
};

export const number = (number: number) => {
  return _.round(number * 100);
};

export const divide = (amount: number, divisor: number) => {
  const d = dinero({ amount, currency: USD });
  const x = Math.round(10000 * (1 / divisor));
  const [d1] = allocate(d, [x, 10000 - x]);

  const { amount: number } = toSnapshot(d1);

  return _.round(number);
};

export const multiplyNumber = (number: number, amount2: number) => {
  const d = dinero({ amount: number, currency: USD });

  const { amount } = toSnapshot(multiply(d, amount2));

  return _.round(amount);
};

export const subtractPercent = (amount: number, percent: number) => {
  const d = dinero({ amount: _.toInteger(amount), currency: USD });
  const [d1] = allocate(d, [percent, 100 - percent]);
  const { amount: number } = toSnapshot(subtract(d, d1));

  return _.round(number);
};

export const getPercent = (amount: number, percent: number) => {
  let _percent = percent;
  let number = 0;

  while (_percent > 0) {
    const tempPercent = _percent > 100 ? 100 : _percent;
    const d = dinero({ amount: _.toInteger(amount), currency: USD });
    const [d1] = allocate(d, [tempPercent, 100 - tempPercent]);
    const snapshot = toSnapshot(d1);
    number += snapshot.amount;
    _percent -= tempPercent;
  }

  return _.round(number);
};

export const addPercent = (amount: number, percent: number) => {
  const d = dinero({ amount: _.toInteger(amount), currency: USD });
  const d2 = dinero({
    amount: getPercent(amount, percent),
    currency: USD,
  });

  const { amount: number } = toSnapshot(add(d, d2));

  return _.round(number);
};

export const Price: FunctionComponent<PriceProps> = memo(
  ({ sum, ceil = false }: PriceProps) => {
    return <>{convertNumber(sum, ceil)}</>;
  },
);
