import {
  ChangeEventHandler,
  FunctionComponent,
  useEffect,
  ReactNode,
  useState,
  DetailedHTMLProps,
  InputHTMLAttributes,
  ChangeEvent,
} from 'react';
import classNames from 'classnames';

type Loading = number | boolean;

export type InputProps = {
  onChange?: (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  loading?: boolean | { delay?: number };
  children?: ReactNode;
} & Omit<
  DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange'
>;

export const Input: FunctionComponent<InputProps> = props => {
  const { loading = false, disabled, className, children, ...rest } = props;

  const [innerLoading, setLoading] = useState<Loading>(!!loading);

  const loadingOrDelay: Loading =
    typeof loading === 'boolean' ? loading : loading?.delay || true;

  useEffect(() => {
    let delayTimer: number | null = null;

    if (typeof loadingOrDelay === 'number') {
      delayTimer = window.setTimeout(() => {
        delayTimer = null;
        setLoading(loadingOrDelay);
      }, loadingOrDelay);
    } else {
      setLoading(loadingOrDelay);
    }

    return () => {
      if (delayTimer) {
        // in order to not perform a React state update on an unmounted component
        // and clear timer after 'loadingOrDelay' updated.
        window.clearTimeout(delayTimer);
        delayTimer = null;
      }
    };
  }, [loadingOrDelay]);

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = props;

    if (innerLoading || disabled) {
      e.preventDefault();
      return;
    }
    (onChange as ChangeEventHandler<HTMLInputElement>)?.(e);
  };

  return (
    <input
      {...rest}
      className={classNames(['Input', className])}
      onChange={handleInput}
      disabled={(typeof loading === 'boolean' && loading) || disabled}
    />
  );
};
