import { createContext, useContext, useState } from 'react';

export type THttpContext = {
  redirect?: string;
};

type HttpContextType = {
  httpContext: THttpContext;
  setHttpContext: React.Dispatch<React.SetStateAction<THttpContext>>;
};

const HttpContext = createContext<HttpContextType | null>(null);

export const HttpProvider = ({
  client,
  children,
}: {
  client: THttpContext;
  children: React.ReactNode;
}) => {
  const [httpContext, _setHttpContext] = useState(client);

  const setHttpContext = (values: THttpContext) => {
    if (typeof window === 'undefined') {
      Object.assign(client, values);
      _setHttpContext(values);
    } else {
      if (values.redirect) {
        window.location.href = values.redirect;
      }
    }
  };

  return (
    <HttpContext.Provider value={{ httpContext, setHttpContext }}>
      {children}
    </HttpContext.Provider>
  );
};

export const useHttpClient = (): HttpContextType => {
  const context = useContext(HttpContext);
  if (!context) {
    throw new Error('useHttpClient must be used within HttpProvider');
  }
  return context;
};
