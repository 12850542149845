import  { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import YaMetrica, { reachGoal, sendApi, sendTransaction } from './YaMetrica';

import propTypes from './propTypes';

export default class YaMetricaProvider extends Component {
  static childContextTypes = {
    yametrica: propTypes,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
  };

  getChildContext() {
    return {
      yametrica: {
        reachGoal,
        sendApi,
        sendTransaction,
      },
    };
  }

  render() {
    const { children, ...rest } = this.props as any;

    return (
      <Fragment>
        {children}
        <YaMetrica {...rest} />
      </Fragment>
    );
  }
}
