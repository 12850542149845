import { FunctionComponent } from 'react';
import { useProfile } from '~components/profile/hooks';
import { useConfig } from '~components/hooks';
import _ from 'lodash';
import { useMount } from 'react-use';
import { convertNumber } from '~ui/Price';

export function sendTransaction({
  coupon,
  revenue,
  price,
  name,
  id,
  paymentMethodName,
  userId,
}) {
  window?.dataLayer?.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window?.dataLayer?.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: String(id),
      value: revenue,
      currency: window['currency'],
      coupon,
      affiliation: 'Deposit',
      paymentMethodName,
      userId,
      items: [
        {
          item_name: name,
          item_id: String(id),
          price,
          quantity: 1,
        },
      ],
    },
  });
}

export function reachGoal(...props: any[]) {
  sendApi(0, 'reachGoal', ...props);
  sendApi(1, 'event', ...props);
  //  sendApi(2, 'trackCustom', ...props);
}

export function sendApi(type: number, ...props: any[]) {
  try {
    if (typeof window === 'undefined') {
      throw new Error('Not found type Metric');
    }

    if (type === 1) {
      return window.gtag && window.gtag(...props);
    } else if (type === 2) {
      //return window?.fbq && window?.fbq(...props);
    }

    return (
      window.ym &&
      window['yaCounter'] &&
      window.ym(window['yaCounter'], ...props)
    );
  } catch (e) {
    //  console.error(e);
  }
}

const YaMetrica: FunctionComponent = () => {
  const getConfig = useConfig();
  const getProfile = useProfile();

  useMount(() => {
    if (_.includes(getConfig?.userAgent, 'Lighthouse')) {
      return null;
    }

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://mc.yandex.ru/metrika/tag.js';

    document.body.appendChild(script);

    const scriptGtag = document.createElement('script');
    scriptGtag.async = true;
    scriptGtag.src =
      'https://www.googletagmanager.com/gtm.js?id=' + getConfig?.seo.googleId;

    document.body.appendChild(scriptGtag);

    sendApi(0, 'init', {
      id: getConfig?.seo.yaCounter,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
      triggerEvent: true,
      ecommerce: 'dataLayer',
    });

    sendApi(1, 'js', new Date());
    sendApi(1, 'config', getConfig?.seo.googleId);

    sendApi(2, 'init', getConfig?.seo.facebookId);
    sendApi(2, 'track', 'PageView');

    window['jivo_onClose'] = () => {
      document.querySelector('jdiv').className = '';
    };

    window['jivo_onLoadCallback'] = () => {
      setUserInfo();
    };
  });

  const setUserInfo = () => {
    if (getProfile) {
      window['jivo_api']?.setCustomData([
        {
          title: `Профиль ${getProfile.userName}`,
          content: String(getProfile.id),
          link: `${location.origin}/user/${getProfile.id}`,
        },
        {
          title: `UserId ${getProfile.userName}`,
          content: String(getProfile.userId),
        },
      ]);

      sendApi(0, 'setUserID', String(getProfile.id));
      sendApi(0, 'userParams', {
        money: convertNumber(getProfile.money),
        isPay: getProfile.isPay,
        userName: getProfile.userName,
        userId: getProfile.userId,
      });

      window.dataLayer.push({
        user_id: String(getProfile.id),
      });
    }
  };

  if (_.includes(getConfig?.userAgent, 'Lighthouse')) {
    return null;
  }

  return (
    <>
      <noscript>
        <img
          height="1"
          width="1"
          style={{ position: 'absolute', left: '-9999px' }}
          src={`https://www.facebook.com/tr?id=${getConfig?.seo.facebookId}&ev=PageView&noscript=1`}
        />
      </noscript>
      <noscript>
        <div>
          <img
            src={`https://mc.yandex.ru/watch/${getConfig?.seo.yaCounter}`}
            style={{ position: 'absolute', left: '-9999px' }}
          />
        </div>
      </noscript>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${getConfig?.seo.googleId}`}
          style={{ position: 'absolute', left: '-9999px' }}
        ></iframe>
      </noscript>
    </>
  );
};

export default YaMetrica;
